<template>
  <div class="overlay" v-if="loading">
    <van-loading type="spinner"
                 vertical
                 color="#1989fa" >
      <span class="f14">
     {{loadingText}}
    </span></van-loading>
  </div>
</template>

<script>
export default {
  name: "loading",
  data () {
      return {

      }
  },
  props:{
    loading:{
      type:Boolean,
      default:true
    },
    loadingText:{
      type:String,
      default: ()=>{
        return '加载中...'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .overlay{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: rgba(255,255,255,.5);

  }
</style>
