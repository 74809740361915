import http from "./http";
/**
 *查看工资条
 */
export const lookSalary=(params)=>{
    return http({
        url:'/api/magigene-hr/salarypassword/getH5Sign',
        method: 'post',
        params:params
    })
}
/**
 *获取工资条分页
 */
export const fetchSalaryList =(params)=>{
    return http({
        url:'/api/magigene-hr/my/realPaySalaryList',
        method: 'post',
        params
    })
}
/**
 * 用户登录
 */
export const fetUserLogin=(params)=>{
    return http({
        url:'/api/blade-auth/oauth/token',
        method: 'post',
        params
    })
}
/**
 * 查看工资详情
 */
export const fectLooksalaryInfo=(params)=>{
    return http({
        url:'/api/magigene-hr/my/salarySheetDetail',
        method: 'post',
        params
    })
}
/**
 * 查看工资详情
 */
export const getDetail=(id)=>{
    return http({
        url:'/api/magigene-hr/csemployeeyearendbonus/detail',
        method: 'post',
        params:{
            id
        }
    })
}
/**
 * 定时获取token
 */
export const fetchToken=(params)=>{
    return http({
        url:'/api/blade-auth/oauth/token',
        method: 'post',
        headers:{'Tenant-Id':params.tenant_id},
        params:{
            grant_type: 'refresh_token',
            scope: 'all',
            refresh_token:params.refresh_token
        }
    })
}
